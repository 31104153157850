import React from "react";
import briefcaseImage from '../../assets/office-briefcase.svg';
import graphupImage from '../../assets/graph-up.svg';
import teamImage from  '../../assets/team.svg'

interface cardProps {
    imageCard: string;
    alt: string;
    cardName: string;
    cardText: string;
}

const Card = ({ imageCard, alt, cardName, cardText }: cardProps) => {
    const images = {
        'office-briefcase.svg': briefcaseImage,
        'graph-up.svg': graphupImage,
        'team.svg': teamImage
    };

    return (
        <div className="hover:pulse-animation w-72 h-80 rounded-[60px] overflow-hidden shadow-lg pt-6 pb-10 px-11 bg-terciary text-center md:text-left flex flex-col items-center">
            <div className="flex items-center justify-center h-14 w-12 rounded-full bg-white mb-4 ">
                <img src={images[imageCard]} alt={alt} width={24} height={24} loading="lazy" />
            </div>
            <h2 className="text-xl font-bold mb-2 text-center">
                {cardName}
            </h2>
            <hr className="border-t-1 border-gray-300 w-full my-4" />
            <p className="text-black text-base text-center">
                {cardText}
            </p>

        </div>
    )
}

export default Card; 