import React from "react";

interface ButtonProps{
    buttonText: string; 
    buttonColor: string;
    onClick: () => void;
}


const Botao = ({ buttonText, buttonColor, onClick }: ButtonProps) =>{
    return (
        <button
            onClick={onClick}
            className={`px-6 py-3 rounded-lg font-bold text-white ${buttonColor} hover:bg-opacity-80 transition duration-300`}
        >
            {buttonText}
        </button>
    );
}

export default Botao; 