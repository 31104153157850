import Header from "../../components/header/page.tsx";
import Footer from "../../components/footer/page.tsx";
import React from "react";


const About = () => {

    return (
        <>
            <div className="text-white">
            <Header backgroundColor="black" borderRadius="0px" btnBgColor="white" btnColor="black" logoSrc={require("../../assets/Jocamp branca.png")} />
            </div>
            <div className="flex flex-col justify-center text-center md:mx-20 gap-24 mt-20">
                <div className="flex flex-col md:flex-row items-center gap-20">
                    <div className="w-full md:2/5 text-center md:text-left">
                        <h1 className="text-4xl font-bold text-black mb-4 text-center">Conheça a Jocamp!</h1>
                        <br></br>
                        <p className="mb-8">A nossa empresa se orgulha de ser guiada por valores que colocam nossos clientes no centro de tudo o que fazemos. Acreditamos na transparência, integridade e excelência como pilares fundamentais que norteiam cada decisão. Nosso compromisso é oferecer produtos e serviços de alta qualidade, sempre com foco na satisfação e no sucesso dos nossos clientes. Trabalhamos com paixão e dedicação para construir relações duradouras, baseadas na confiança e no respeito mútuo. Estamos sempre em busca de inovação e melhoria contínua, para garantir que cada cliente receba o melhor, porque para nós, o seu sucesso é a nossa maior conquista.</p>
                    </div>
                    <div className="w-full md:w-3/5">
                        <img src={require("../../assets/Fachadas sem fundo2.png")} alt="Imagem da Jocamp" loading="lazy" className="w-full h-auto"></img>
                    </div>
                </div>
                <div className="flex flex-col gap-16">
                    <h1 className="text-4xl font-bold text-black mb-4">Venha nos visitar!</h1>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14696.801796222402!2d-47.1833854!3d-22.9428438!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8b7704f18bf6b%3A0x48b2b0b55af70276!2sJocamp%20Contabilidade%20Ltda!5e0!3m2!1spt-BR!2sbr!4v1726843897081!5m2!1spt-BR!2sbr" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="w-full"></iframe>




            <Footer />
        </>


    )

}

export default About; 