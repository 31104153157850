import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Main from '../pages/main/page.tsx'
import Contact from '../pages/contact/page.tsx'
import Login from '../pages/login/page.tsx'
import Error from '../pages/error/page.tsx'
import About from '../pages/aboutus/page.tsx'
import ScrollToTop from '../components/ScrollToTop/page.tsx'

function RoutesApp() {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/contato" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Error />} />
                <Route path="/Sobre" element={<About />} />
            </Routes>
        </>

    )
}

export default RoutesApp