import React, { useRef } from "react";
import Header from "../../components/header/page.tsx";
import { FaMapMarkerAlt, FaPhoneAlt, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import Footer from "../../components/footer/page.tsx";
import { useForm, SubmitHandler } from 'react-hook-form'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from '../../assets/logosemfundo.png'


const Contact = () => {


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);
        const emailData = {
            nome: formData.get('from_name'),
            email: formData.get('from_email'),
            mensagem: formData.get('message')
        };

        fetch('https://api.jocamp.com.br/api/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        }).then((response) => {
            console.log('Status da resposta:', response.status);
            console.log(response)
            if (!response.ok) {  // Se a resposta não for 200-299
                throw new Error(`Erro na requisição: ${response.status}`);
            }
            return response.text();
        })
            .then((data) => {
                console.log('Dados recebidos do backend:', data);
            
                if (data === "Email sent successfully!") {
                    toast.success("Mensagem enviada com sucesso!");
                } else {
                    toast.error("Falha ao enviar mensagem, tente novamente!");
                }

            })
            .catch((error) => {
                console.error('Erro ao enviar mensagem:', error);
                toast.error("Falha ao enviar mensagem, tente novamente!");
            });
    };

    return (
        <>
            <Header backgroundColor="fff" borderRadius="0px" btnBgColor="dark" btnColor="white" />
            <div className="flex flex-col md:flex-row items-start justify-center p-6 md:p-16 gap-6 mt-17">
                <div className="md:flex-row flex flex-col gap-14 w-full">
                    <div className="space-y-6 md:text-left flex-col">
                        <h1 className="font-bold text-5xl text-center">Fale conosco!</h1>
                        <p className="text-center">Se você tiver qualquer dúvida, sinta-se à vontade para contatar-nos</p>


                        <div className="flex items-center">
                            <FaMapMarkerAlt className="text-black mr-2" />
                            <p>R. Manoel Machado Pereira, 142 - Parque Valença I, Campinas - SP, 13058-500</p>
                        </div>


                        <div className="flex items-center">
                            <FaPhoneAlt className="text-black mr-2" />
                            <p>(19) 3261-1530</p>
                        </div>

                        <div className="flex items-center">
                            <FaWhatsapp className="text-black mr-2" />
                            <p>(19) 99775-0133</p>
                        </div>

                        <div className="flex items-center">
                            <FaEnvelope className="text-black mr-2" />
                            <p>jocampcontab@hotmail.com</p>
                        </div>

                        <div className="flex items-center">
                            <FaEnvelope className="text-black mr-2" />
                            <p>jocamp.escola@gmail.com</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-10 items-center">
                    <img src={require("../../assets/conversa vetor.png")} alt="contact image" loading="lazy" className="rounded-[70px] h-[300px] w-[750px] "></img>

                    <form className="w-full" onSubmit={sendEmail} ref={form}>
                        <label>Nome completo</label>
                        <input className="color: bg-gray-300 p-1 rounded-full w-full"
                            type="text"
                            name="from_name"
                            required
                        />
                        <label>Email</label>
                        <input className="color: bg-gray-300 p-1 rounded-full w-full"
                            type="email"
                            name="from_email"
                            required
                        />
                        <label>Mensagem</label>
                        <textarea className="color: bg-gray-300 p-5 rounded-xl w-full"
                            name="message"
                            required
                        />

                        <div className="flex justify-center mt-4">
                            <input className="bg-green-600 p-4 rounded-xl text-white hover:bg-green-900" type="submit" value="Enviar" />
                        </div>

                    </form>

                </div>
            </div>

            <FloatingWhatsApp phoneNumber="19997500404" accountName="Jocamp" avatar={logo} placeholder="mensagem" chatMessage="Ola! 🤝 Como podemos ajudá-lo?" />
            <ToastContainer />
            <Footer />

        </>
    )
}

export default Contact; 