import React from "react"
import { Link } from 'react-router-dom'
import Botao from "../../components/botao/page.tsx"

const Login = () => {
    return (
        <div className='flex item-center justify-center min-h-screen bg-gray-200 m-0 p-0'>
            <div className='bg-white p-8 rounded-lg shadow-lg sm:max-w-sm w-full sm:mt-28 sm:mb-28'>
                <div className='flex justify-center mb-6'>
                    <Link to="/">
                        <img src={require("../../assets/logosemfundo.png")} alt="" loading="lazy"  className="w-20 h-24" />
                    </Link>
                </div>
                <form>
                    <div className="mb-4">
                        <label className="block text-lg font-bold mb-2" htmlFor="login">
                            
                            Login
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="login"
                            type="text"
                            placeholder="Digite seu login"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-lg font-bold mb-2">
                            Senha
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="Digite sua senha"
                        />
                         <div className="flex justify-center mt-4">
                            <Botao buttonColor="bg-green-600" buttonText="Enviar" />
                        </div>
                    </div>
                    <div className="flex items-center justify between">
                        <a
                            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                            href="#"
                        >
                            esqueceu sua senha?
                        </a>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default Login;

