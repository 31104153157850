
import Header from '../../components/header/page.tsx';
import Card from '../../components/card/page.tsx';
import Banner from '../../components/banner/page.tsx';
import Footer from '../../components/footer/page.tsx';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import Marquee from 'react-fast-marquee';
import React from 'react';

function Main() {

  const { ref: refAge, inView: inViewAge } = useInView()

  const { ref: refProcess, inView: inViewProcess } = useInView();

  const { ref: refPrincipal, inView: inViewPrincipal } = useInView({
    triggerOnce: true
  });
  const { ref: refBanner1, inView: inViewBanner1 } = useInView({
    triggerOnce: true
  });
  const { ref: refMarquee, inView: inViewMarquee } = useInView({
    triggerOnce: true
  });

  const { ref: refPanel1, inView: inViewPanel1 } = useInView({
    triggerOnce: true
  })

  const { ref: refBanner2, inView: inViewBanner2 } = useInView({
    triggerOnce: true
  });
  const { ref: refInfo1, inView: inViewInfo3 } = useInView({
    triggerOnce: true
  })
  const { ref: refBanner3, inView: inViewBanner3 } = useInView({
    triggerOnce: true
  });
  const { ref: refFooter, inView: inViewFooter } = useInView({
    triggerOnce: true
  });

  return (
    <HelmetProvider>
      <div className="min-h-screen bg-primary">

        <Helmet>
          <title>Jocamp Contabilidade - Transforme seu negócio com serviços contábeis</title>
          <meta name="description" content="A Jocamp oferece serviços de contabilidade personalizados para ajudar empresas e profissionais de Campinas e região a alcançarem seus objetivos com soluções estratégicas." />
        </Helmet>

        <div ref={refPrincipal}>
          {
            inViewPrincipal && (
              <div className="relative bg-secundary md:mr-5 md:ml-5 md:mt-5 md:rounded-[70px] text-center md:text-left">
                <Header backgroundColor="terciary" borderRadius="70px" btnBgColor="primary" btnColor="black" />
                <div className="flex flex-col items-center justify-center p-6">
                  <div className="max-w-6x1 mx-auto flex flex-col md:flex-row items-center bg-transparency p-8 rounded-lg">
                    <div className="md:w-1/2 space-y-6">
                      <span className="inline-block bg-blue-100 text-blue-800 text-sm font-medium px-4 py-2 rounded-full arrow-home" >Seu sucesso, nossa prioridade</span>
                      <h1 className="text-4xl font-bold text-black">Transforme seu negócio com serviços contábeis especializados</h1>
                      <p className="text-black">Atendendo Campinas e região, a Jocamp tem a missão de ajudar pessoas físicas e jurídicas conquistarem seus objetivos e alcançarem a prosperidade. Com um compromisso inabalável com o sucesso de nossos clientes, oferecemos soluções personalizadas que impulsionam o crescimento e a realização pessoal e profissional.</p>
                      <div className="flex space-x-4">
                        <Link to={"/sobre"}>
                          <a className="inline-block px-6 py-3 bg-black text-white text-sm rounded-lg font-bold text-center hover:bg-gray-600">Comece aqui</a>
                        </Link>
                        <Link to={"/contato"}>
                          <a className="inline-block px-6 py-3 bg-primary text-black border border-black-300 text-sm rounded-lg font-bold text-center hover:bg-gray-300">Entre em contato</a>
                        </Link>

                      </div>
                    </div>

                    <div className="md:w-1/2 mt-8 md:mt-0">
                      <img src={require("../../assets/Happy-Officeedit.avif")} alt="Meeting" className="rounded-lg ml-3 " width={650} height={433} loading='lazy' />
                    </div>
                  </div>
                </div>

              </div>)
          }

        </div>


        <div className="mt-10 md:ml-5 md:mr-5">
          <div ref={refBanner1}>
            {inViewBanner1 && (
              <div className="relative w-full h-[300px] md:rounded-[70px] overflow-hidden overlay">
                <img
                  src={require("../../assets/crowd-img.jpg")}
                  alt="card img"
                  loading='lazy'
                  className="rounded-lg object-cover w-full h-full"
                />




                <div className="absolute inset-0 flex flex-col md:flex-row justify-end md:justify-between items-start  md:items-end p-5 md:px-16 md:py-5 overlay ">
                  <div className="w-[300px] md:w-1/2 mb-1 md:mb-4">
                    <h2 className="text-white text-sm md:text-5xl break-words font-bold">Gerando crescimento através de estratégias de negócio</h2>
                  </div>
                  <div className="flex flex-col text-left md:text-right md:mb-4">
                    <h1 ref={refAge} className="text-white text-lg md:text-4xl font-bold mt-2">  {inViewAge ? <CountUp start={5} end={25} /> : ''} anos </h1>
                    <p className="text-white text-sm mt-2 break-words"> em serviços contábeis</p>
                  </div>
                </div>
              </div>)}
          </div>


          <div ref={refMarquee} >
            {inViewMarquee &&
              (<div className="">
                <Marquee
                  autoFill={true}

                >
                  <div className="my-16 mr-4" >
                    <Card imageCard={"office-briefcase.svg"} alt="briefcase" cardName="Consultoria tributária" cardText="Otimização da carga tributária e orientação para o cumprimento das obrigações fiscais." />
                  </div>
                  <div className="mr-4">
                    <Card imageCard={"graph-up.svg"} alt="graph-up" cardName="Contabilidade Gerencial" cardText="Análise financeira e relatórios gerenciais para tomada de decisões estratégicas." />
                  </div>
                  <div className="mr-4">
                    <Card imageCard={"team.svg"} alt="team" cardName="Gestão de Folha de Pagamento" cardText="Processamento preciso de folhas de pagamento e conformidade com leis trabalhistas." />
                  </div>
                </Marquee>
              </div>)}
          </div>

          <div ref={refPanel1}>

            {inViewPanel1 && (<div className="mt-10" >

              <div className="flex flex-col md:flex-row items-center bg-white p-6 rounded-lg">
                <div className="md:w-[60%]">
                  <img src={require("../../assets/crowd-img3.jpg")} alt="Business Meeting" height={1000} width={1000} loading='lazy' className="rounded-lg" />
                </div>
                <div className="md:w-1/2 mt-4 md:mt-0 md:ml-6">
                  <h2 className="text-4xl font-bold text-center mb-5">Confiança com nossos melhores contadores</h2>
                  <ul className="mt-4 space-y-2">
                    <div className="flex text-center justify-around flex-wrap">
                      <li ref={refProcess} className="w-[40%] m-2">
                        <div className="text-bold text-4xl"> +{inViewProcess ? <CountUp start={0} end={500} /> : ''} </div>
                        processos concluídos</li>
                      <li ref={refProcess} className="w-1/2 m-2" >
                        <div className="text-bold text-4xl">+{inViewProcess ? <CountUp start={0} end={200} /> : ''} </div>empresas abertas</li>
                      <li ref={refProcess} className="w-[40%] m-2">
                        <div className="text-bold text-4xl">+{inViewProcess ? <CountUp start={0} end={40} /> : ''} </div>escolas atendidas</li>
                      <li ref={refProcess} className="w-1/2 m-2">
                        <div className="text-bold text-4xl">+{inViewProcess ? <CountUp start={0} end={150} /> : ''} </div>empresas atendidas</li>


                    </div>

                  </ul>
                </div>
              </div>
            </div>)}
          </div>




        </div>

        <div ref={refBanner2}>
          {
            inViewBanner2 && (<Banner bannertext="Transforme seu negócio com serviços contábeis especializados" bannercolor="banner" />)
          }

        </div>


        <div ref={refInfo1}>
          {inViewInfo3 && (<div className="w-full flex flex-col md:flex-row mt-8 justify-start p-12">
            <div className="w-full md:w-[65%]">
              <h1 className="text-bold text-5xl">Unindo tecnologia com a tradição</h1>
              <p className="mt-11 text-bold">A Jocamp investe um vasto número de horas na melhoria contínua de suas tecnologias, dedicando-se incansavelmente a impulsionar suas equipes a alcançarem sempre os melhores resultados. Com um compromisso inabalável com a excelência, buscamos não apenas atender, mas superar as expectativas de nossos clientes, garantindo que cada projeto seja desenvolvido com a mais alta qualidade e inovação.</p>
            </div>
            <div>
              <img src={require("../../assets/vector meeting.jpg")} alt="Business Meeting" height={1000} width={1000} loading='lazy'></img>
            </div>

          </div>)}
        </div>


        <div ref={refBanner3}>
          {
            inViewBanner3 && (<Banner bannertext="Fale com nossos melhores contadores agora!" bannercolor="banner" />)
          }
        </div>



        <div ref={refFooter}>
          {inViewFooter && (<Footer />)}
        </div>




      </div >
    </HelmetProvider>
  );
}

export default Main;
