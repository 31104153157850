import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
    return (

        <footer className="bg-black text-white py-6">
            <div className="w-full flex flex-col justify-between items-center">
                <div className="mt-8 mb-8">
                    <img src={require("../../assets/Jocamp branca.png")} alt="Logo footer" height={250} width={250} loading="lazy" />
                </div>
                <hr className="border-t-1 border-gray-300 w-full m-4 max-w-[70%]" />
                <div className="flex flex-col w-[60%] justify-center items-start">
                    <ul>
                        <Link to={"/"}>
                            <li><a className="hover:underline text-sm">Página inicial</a></li>
                        </Link>
                        <Link to={"/sobre"}>
                            <li><a  className="hover:underline text-sm">Sobre nós</a></li>
                        </Link>
                        <Link to={"/contato"}>
                            <li><a className="hover:underline text-sm">Contato</a></li>
                        </Link>
                    </ul>




                </div>
                <div className="flex w-full justify-around mt-8 px-10">
                    <p className="text-xs text-bold mt-2">Jocamp 2024 Todos os direitos reservados</p>
                    <p className="text-xs text-bold mt-2">Política de privacidade</p>
                </div>
            </div>
        </footer >)
}

export default Footer; 