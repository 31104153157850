import Header from "../../components/header/page.tsx";
import Footer from "../../components/footer/page";
import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
    return (
        <>
            <Header backgroundColor="fff" borderRadius="0px" btnBgColor="dark" btnColor="white" />
            <div className="flex flex-row items-center justify-center h-screen bg-white">
                <div>
                    <h1 className="text-4xl font-bold text-black mb-4">Oops!</h1>
                    <p className="text-lg text-gray-600 mb-8">Estamos trabalhando nessa funcionalidade</p>
                </div>

                <div className="relative">
                    <img
                        src={require("../../assets/Error image.jpg")}
                        alt="Erro funcionalidade"
                        className="w-96"
                    />
                </div>
            </div>
        </>
    )
}

export default Error; 