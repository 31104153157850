import { BrowserRouter } from "react-router-dom";
import RoutesApp from './routes/index.tsx'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from './assets/jocampwhatsapp.jpg'


function App() {
  return (

    <BrowserRouter>
      <RoutesApp />
     
      <FloatingWhatsApp
        phoneNumber="19997750133"
        accountName="Jocamp"
        avatar={logo}
        placeholder="mensagem"
        chatMessage="Olá! 
        🤝 Como podemos ajudá-lo?"
        statusMessage="Usualmente responde dentro de 1 hora" />

    </BrowserRouter>
  )
}
export default App; 
