import {Link} from "react-router-dom";
import React from "react";
interface HeaderProps {
    backgroundColor: string;
    borderRadius: string;
    btnBgcolor: string;
    btnColor: string;
    logoSrc: string;

}

const Header = ({ backgroundColor, borderRadius, btnBgColor, btnColor, logoSrc }: HeaderProps) => {
    return (
        <header className={`bg-${backgroundColor} md:rounded-[${borderRadius}] p-10 flex flex-col sm:flex-wrap sm:flex-row justify-between items-center`}>
            <Link to={"/"}>
            <div className="flex items-center mb-8 sm:mb-0 space-x-4">
                <img src={logoSrc || require("../../assets/logoCompridoSemPadding.png")} alt="Logo" loading="lazy" className="max-w-44 h-20" />
            </div>
            </Link>
            <nav className="flex space-x-6 mb-4 ml-4 mr-4 sm:mb-0">
                <Link to={"/"}><a className="hover:text-gray font-bold">Página Inicial</a></Link>
                <Link to={"/sobre"}><a className="hover:text-gray font-bold">Sobre nós</a></Link>
               <Link to="/contato"> <a  className="hover:text-gray font-bold">Contato</a></Link>
            </nav>
            <button className={`bg-${btnBgColor} hover:bg-gray-300 text-${btnColor} font-bold py-3 px-9 rounded-full mt-4 md:mt-0`}>
                <Link to="/login">Login</Link>
                  
                
            </button>
        </header>
    );
};

export default Header;