import React from "react";
import { Link } from "react-router-dom";

interface BannerProps {
    bannertext: string;
    bannercolor: string;
}

const Banner = ({ bannertext, bannercolor }: BannerProps) => {
    return (
        <div className={`w-full bg-${bannercolor} mt-8 text-entrance-left`}>
            <div className="p-20 flex flex-col md:flex-row justify-around items-center">
                <h1 className="text-bold text-white md:w-1/2 text-3xl text-entrance-left">{bannertext}</h1>
                <Link to={"/contato"}>
                    <a  className="inline-block px-6 py-3 bg-white text-black text-sm rounded-lg font-bold text-center hover:bg-gray mt-8 md:mt-0 hover:bg-gray-300 text-entrance-left">Comece aqui</a>
                </Link>
            </div>

        </div>
    )
}

export default Banner; 